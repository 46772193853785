import React, { useState } from "react";
import { isYesOrNoEnum } from "../../../types/types";
import CurrencyUtil from "../../../utils/CurrencyUtil";
import { useMutation, useQueryClient } from "react-query";
import SwapService, { LinkTransactionsToSwapResponse, RequestSwapModel } from "../../../services/swap/swapService";
import { AxiosResponse } from "axios";
import { Button, Icon, Input, Text, View } from "hubchain-storybook-design-pattern";
import styles from "../styles";
import SwapModalTitle from "./SwapModalTitle";
import { TCurrencySymbol } from "../../../types/currency";
import { ActivityIndicator } from "react-native";
import SwapModalDetailRow from "./SwapModalDetailRow";
import { FormikProps } from "formik";
import { SwapModalFormData } from "../UserSwapModal";
import { RequestSwapModelExtended } from "../../../../pages/swap";
import { useTranslation } from "react-i18next";
// import { NetworkNameId } from "../../../types/network";

interface SwapModalFundsCardProps {
  swapForm: FormikProps<SwapModalFormData>,
  request?: RequestSwapModelExtended
}

const SwapModalFundsCard = ({ swapForm, request }: SwapModalFundsCardProps) => {
  const { t } = useTranslation();
  const swap = request?.swap;

  const queryClient = useQueryClient();

  const [action, setAction] = useState<null | "edit" | "add">(null);
  const [transactionsIds, setTransactionsIds] = useState("");
  const [error, setError] = useState("");

  const [transactions, setTransactions] = useState(
    swap?.transactionsIn?.transactions || []
  );

  const swapTransactionsTotal = transactions?.reduce((sum, transaction) => {
    const isValid = transaction.isPaid === isYesOrNoEnum.YES;

    if (isValid) {
      return sum + CurrencyUtil.getNumberByValue(transaction.totalReceived);
    }

    return sum;
  }, 0);

  const editFundsDisabled = action !== "edit";
  const allowMultipleTransactions = false;

  const { mutate: linkTransactionsToSwap, isLoading } = useMutation({
    mutationFn: SwapService.linkTransactionsToSwap,
    onSuccess: (data) => {
      const { data: response } =
        data as AxiosResponse<LinkTransactionsToSwapResponse>;

      setAction(null);
      setTransactionsIds("");

      request = response.data;
      setTransactions(request.swap?.transactionsIn?.transactions);
      queryClient.refetchQueries("swaps");
    },
    onError: (error) => {
      setError(t(`swap-modal.funds.errors.id`));
      request!.isLoading = false;
    },
  });

  const handleLinkTransactionsToSwap = () => {
    const transactions = transactionsIds.split(",").map((transactionId) => ({
      transactionId,
      network:  "p2p-mainnet",
    }));

    request!.isLoading = true;
    linkTransactionsToSwap({ id: request!.id, transactions });
  };

  const TitleAddon = () => {
    return (
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 8,
        }}
      >
        {action === null ? (
          <>
            {!transactions.length && (
              <Button
                label={""}
                icon={<Icon name={"AddCircle"} fontSize={"20px"} />}
                size={"table"}
                fillVariant={"ghost"}
                width={"28px"}
                onClick={() => setAction("add")}
                disabled={isLoading}
                disableHover={isLoading}
              />
            )}
          </>
        ) : (
          <>
            <Button
              label={""}
              icon={<Icon name={"CheckmarkIcon"} fontSize={"16px"} />}
              size={"table"}
              variant={"success"}
              fillVariant={"ghost"}
              width={"28px"}
              onClick={() => handleLinkTransactionsToSwap()}
              disabled={isLoading || !transactionsIds}
              disableHover={isLoading || !transactionsIds}
            />

            <Button
              label={""}
              icon={<Icon name={"CloseIcon"} fontSize={"16px"} />}
              size={"table"}
              variant={"danger"}
              fillVariant={"ghost"}
              width={"28px"}
              onClick={() => {
                setAction(null);
                setTransactionsIds("");
              }}
              disabled={isLoading}
              disableHover={isLoading}
            />
          </>
        )}
      </View>
    );
  };

  return (
    <View
      style={[
        styles.card,
        styles.detailsCard,
        { flex: "none", minHeight: 159, gap: 8 },
      ]}
    >
      <SwapModalTitle
        title={t(
          `swap-modal.funds.title${editFundsDisabled ? "-no-edit" : ""}`
        )}
        rightAddon={editFundsDisabled ? null : <TitleAddon />}
      />

      {action === null ? (
        <>
          {transactions.length && !isLoading ? (
            <>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  maxHeight: 46,
                  overflow: "auto",
                  flex: 1,
                  paddingRight: 4,
                }}
              >
                {transactions.map((transaction) => {
                  const isPaid = (transaction.isPaid === isYesOrNoEnum.YES);

                  return (
                    <View
                      key={transaction.transactionId}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text style={{ color: "#0052cc" }} size={"11px"}>
                        {transaction.transactionId}
                      </Text>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 4,
                        }}
                      >
                        <Text
                          style={{ color: "#0052cc" }}
                          size={"11px"}
                        >
                          {CurrencyUtil.formatCurrency(
                            transaction.totalAmount,
                            transaction?.currency ? TCurrencySymbol[transaction?.currency] : TCurrencySymbol.BRL
                          )}
                        </Text>
                      </View>
                      <Text style={{ color: "white", backgroundColor: isPaid ? "#188351" : "#ff0000", paddingLeft: 3, paddingRight: 3, paddingTop: 2, paddingBottom: 3, fontSize: 10, borderRadius: 4 }} size={"11px"}>
                        {`${isPaid
                            ? t("swap-modal.funds.is-paid.Y")
                            : t("swap-modal.funds.is-paid.N")
                          }`}
                      </Text>

                    </View>
                  );
                })}
              </View>
            </>
          ) : (
            <>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxHeight: 46,
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <ActivityIndicator size={24} color={"#7b1fa2"} />
                ) : (
                  <Text variant={"gray"} size={"12px"}>
                    {t(`swap-modal.funds.no-funds`)}
                  </Text>
                )}
              </View>
            </>
          )}

          <View style={[styles.divider]} />

          <SwapModalDetailRow
            label={t("swap-modal.funds.balance")}
            value={CurrencyUtil.formatCurrency(
              swapTransactionsTotal,
              TCurrencySymbol.BRL
            )}
            fontSize={"12px"}
            height={"20px"}
          />
        </>
      ) : (
        <>
          <Input
            placeholder={
              allowMultipleTransactions
                ? "AAA,BBB,CCC..."
                : t(`swap-modal.funds.input-placeholder`)
            }
            value={transactionsIds}
            disabled={isLoading}
            onChange={(value) => {
              setTransactionsIds(value);
              !!error && setError("");
            }}
            mask={"alphanumericSpaceAndComma"}
            errorMessage={error}
            variant={!!error ? "danger" : "primary"}
          />
          {allowMultipleTransactions && (
            <>
              <View style={[styles.divider]} />
              <Text
                variant={"gray"}
                size={"0.675rem"}
                style={{ textAlign: "left" }}
              >
                {t(`swap-modal.funds.info`)}
              </Text>
            </>
          )}
        </>
      )}
    </View>
  );
}

export default SwapModalFundsCard;
