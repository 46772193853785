import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Button,
	Checkbox,
	Icon,
	Input,
	Modal,
	Select,
	SelectSearch,
	Text,
	View,
} from "hubchain-storybook-design-pattern";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserModel } from "../../models/UserModel";
import UsersService from "../../services/approvedUsers/UsersService";
import { TCurrencySymbol } from "../../types/currency";
import styles from "./styles";
import {
	AvailableIconsType,
	IconVariantsType,
} from "hubchain-storybook-design-pattern/lib/components/Icon/types";
import { useTranslation } from "react-i18next";
import { SelectOptionModel } from "hubchain-storybook-design-pattern/lib/components/Select/types";
import {
	ActivityIndicator,
	TouchableOpacity,
	TouchableWithoutFeedback,
} from "react-native";
import CurrencyUtil from "../../utils/CurrencyUtil";

import { useFormik } from "formik";
import * as Yup from "yup";
import { SelectSearchOption } from "hubchain-storybook-design-pattern/lib/components/SelectSearch/SelectSearch";
import SwapService, {
	QuoteSwapDataResponse,
} from "../../services/swap/swapService";
import Util from "../../utils";
import { useAlert } from "../../hooks/useAlert";
import { useDebouncedValue } from "../../hooks/useDebounce";
import { useCurrency } from "../../hooks/useCurrency";
import {
	ICurrencyTicker,
	INetwork,
} from "../../services/currency/currencyService";
import WalletService from "../../services/wallet/walletService";
import { RequestTypeEnum } from "../../enums/RequestType.enum";
import { RequestStatusId } from "../../enums/RequestStatusId.enum";

import SwapModalTitle from "./components/SwapModalTitle";
import SwapModalDetailsCard from "./components/SwapModalDetailsCard";
import { RequestSwapModelExtended } from "../../../pages/swap";
import SwapModalFundsCard from "./components/SwapModalFundsCard";
import moment from "moment-timezone";
import SwapChangeTypeButton from "./components/SwapChangeTypeButton";
import SwapAvailableBalanceView from "./components/SwapAvailableBalanceView";
import { SwapModalInputNetwork } from "./components/SwapModalInputs";
import { CurrencyTypeEnum } from "../../models/CurrencyModel";
import { isMobile } from "react-device-detect";

import { useMultiTenancy } from "../../services/multi-tenancy/multi-tenancy";
import { TenantAppDomainEnum } from "../../services/multi-tenancy/types";
import { useAuth } from "../../services/auth";

export type SwapModalActionType = "edit" | "new" | "cancel" | "view";
export enum SwapModalSwapDirectionEnum {
	fromCryptoToFiat,
	fromFiatToCrypto,
}
interface UserSwapModalProps {
	userId?: string;
	userData?: any;
	request?: RequestSwapModelExtended;
	currencyIn?: TCurrencySymbol | null;
	currencyOut?: TCurrencySymbol | null;
	isCancel?: boolean;
	onClose: () => void;
	action: SwapModalActionType;
}

export interface INetworkOption extends SelectOptionModel {
	value: string;
	walletConfig: {
		placeholder: string;
		maxLength: number;
		validateName: string;
	};
}

interface ICurrencyOption extends SelectOptionModel {
	value: TCurrencySymbol;
	networks: INetwork["nameId"][];
	type: CurrencyTypeEnum;
}

interface IUserOption extends SelectSearchOption {
	user: UserModel;
}

export interface SwapModalFormData {
	type: "swapExactAmountIn" | "swapExactNetAmountOut";
	currencyIn: ICurrencyOption;
	amountIn: string;
	currencyOut: ICurrencyOption;
	netAmountOut: string;
	// network: INetworkOption;
	networkIn: INetworkOption;
	networkOut: INetworkOption;
	addressOut: string;
	sendAddressOutLater: boolean;
	user: IUserOption | null;
}

export interface SwapModalQuoteAmountIn {
	type: "swapExactAmountIn" | "swapExactNetAmountOut";
	entry: "netAmountOut" | "amountIn";
	amountIn?: number;
	netAmountOut?: number;
	currencyIn: TCurrencySymbol;
	currencyOut: TCurrencySymbol;
	networkIn?: string;
	networkOut?: string;
	addressOut: string;
	user: string;
	requestedAt: Date;
}

type CalculatePairAmountParamsNetwork = {
	field: "network";
	amount?: number;
	currencyIn?: TCurrencySymbol;
	currencyOut?: TCurrencySymbol;
	network?: string;
	networkIn?: string;
	networkOut?: string;
	addressOut?: string;
	userId?: string;
	direction?: SwapModalSwapDirectionEnum;
};

type CalculatePairAmountParamsCurrency = {
	field: "amountIn" | "netAmountOut";
	amount?: number;
	currencyIn?: TCurrencySymbol;
	currencyOut?: TCurrencySymbol;
	network?: string;
	networkIn?: string;
	networkOut?: string;
	addressOut?: string;
	userId?: string;
	direction?: SwapModalSwapDirectionEnum;
};

export type SwapModalCalculatePairAmountParams =
	| CalculatePairAmountParamsNetwork
	| CalculatePairAmountParamsCurrency;

const userService = UsersService.getInstance();

const NotAvailableView = () => {
	return (
		<View
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				height: 460,
			}}
		>
			<ActivityIndicator color={"#000"} size={36} />
		</View>
	);
};

const MaxValueButton = ({
	onPress,
	disabled,
}: {
	onPress: () => void;
	disabled: boolean;
}) => {
	const { t } = useTranslation();

	return (
		<TouchableOpacity onPress={onPress} disabled={disabled}>
			<Text variant={"primary"} fontStyle={"bold"} size={"12px"}>
				{t("swap-modal.origin.max-value")}
			</Text>
		</TouchableOpacity>
	);
};

const parseUserToOption = (user: UserModel): IUserOption => {
	return {
		value: user.id,
		label: user.name || user.personLegalName,
		subLabel: user.email,
		user: user,
		query: `${user.name} ${user.personLegalName} ${user.personCompanyId} ${user.email}`,
	};
};

const UserSwapModal = ({
	userId,
	userData,
	request,
	currencyIn,
	currencyOut,
	onClose,
	action,
}: UserSwapModalProps) => {
	const { t } = useTranslation();
	const { currencies, markets } = useCurrency();
	const { showAlert } = useAlert();
	const { user: authUser } = useAuth();
	const { tenant } = useMultiTenancy();

	const swap = useMemo(() => request?.swap, [request]);

	const isCancel = action === "cancel";
	const isEdit = action === "edit";
	const isReadOnly = action === "cancel" || action === "view";

	const [swapDirection, setSwapDirection] =
		useState<SwapModalSwapDirectionEnum>(
			SwapModalSwapDirectionEnum.fromFiatToCrypto
		);
	const modalRef = useRef(null);

	const queryClient = useQueryClient();

	const [exchangeRateMode, setExchangeRateMode] = useState<"InOut" | "OutIn">(
		"OutIn"
	);

	const toggleExchangeRateMode = () => {
		setExchangeRateMode((state) =>
			(state || "InOut") === "InOut" ? "OutIn" : "InOut"
		);
	};

	const {
		data: _cryptohubInfoBalancesData,
		refetch: refetchCryptoHubInfoBalances,
		isFetching: isFetchingCryptoHubInfoBalances,
	} = useQuery(["CryptoHubInfoBalances"], {
		queryFn: SwapService.getCryptoHubInfoBalances,
		staleTime: 60 * 1000 * 5, // 5 minutes
		retry: 2,
		retryDelay: 60 * 1000 * 2,
		refetchOnWindowFocus: false,
	});

	const { mutate: swapCreate, isLoading: isCreatingSwap } = useMutation({
		mutationFn: SwapService.create,
		onSuccess: () => {
			showAlert(t(`swap-modal.actions.new.success`), "success");
			queryClient.refetchQueries(["swaps"]);
			queryClient.refetchQueries(["CryptoHubInfoBalances"]);
			onClose();
		},
		onError: () => {
			showAlert(t(`swap-modal.actions.new.error`), "danger");
		},
	});

	const { mutate: swapUpdate, isLoading: isUpdatingSwap } = useMutation({
		mutationFn: SwapService.update,
		onSuccess: () => {
			showAlert(t(`swap-modal.actions.edit.success`), "success");
			queryClient.refetchQueries(["swaps"]);
			queryClient.refetchQueries(["CryptoHubInfoBalances"]);
			onClose();
		},
		onError: () => {
			showAlert(t(`swap-modal.actions.edit.error`), "danger");
		},
	});

	const { mutate: swapCancel, isLoading: isCancelingSwap } = useMutation({
		mutationFn: SwapService.cancel,
		onSuccess: () => {
			showAlert(t(`swap.actions.cancel.success`), "success");
			queryClient.refetchQueries(["swaps"]);
			queryClient.refetchQueries(["CryptoHubInfoBalances"]);
			request!.swap.status = {
				...(request?.swap?.status ?? {}),
				id: RequestStatusId.CANCELLED,
				key: "CANCELLED",
				nameEn: "Cancelled",
				namePt: "Cancelado",
			};
			onClose();
		},
		onError: () => {
			showAlert(t(`swap.actions.cancel.error`), "danger");
		},
	});

	const {
		mutateAsync: handleQuoteSwap,
		isLoading: isQuotingSwap,
		variables: quotingSwapData,
	} = useMutation({
		mutationFn: SwapService.quoteSwap,
		onError: () => {
			setCalculatedSwapQuote(undefined);
		},
	});

	// Unique user
	const {
		data: getUserResponse,
		refetch: refetchUser,
		isFetching: isLoadingUser,
	} = useQuery(["users", userId], {
		queryFn: () =>
			userService.getUsers({
				search: { id: user?.id ?? userId },
				page: 0,
				limit: 1,
			}),
		enabled: true, // action !== "edit" && !!userId,
		staleTime: 60 * 5 * 1000, // 5 minutes
	});

	const isSubmitting =
		isCreatingSwap || isUpdatingSwap || isCancelingSwap || isLoadingUser;

	const [filter, setFilter] = useState({
		page: 0,
		limit: 20,
		total: 0,
		search: {
			name: { like: "" },
			isLocked: { equal: "" },
		},
	});

	//Multiple users

	const {
		data: getUsersResponse,
		refetch: refetchUsers,
		isFetching: isLoadingUsers,
	} = useQuery(["users"], {
		queryFn: () => userService.getUsers(filter), //
		enabled: action !== "edit" && !userId,
		staleTime: 60 * 5 * 1000, // 5 minutes
	});

	const isLoadingUserData = isLoadingUser || isLoadingUsers;

	const [balanceVisible, setBalanceVisible] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => {
			setBalanceVisible(true);
		}, 0); // Rerender component to fix select modal
	}, []);

	const { currencyInOptions, currencyOutOptions, networkOptions } =
		useMemo(() => {
			const availableCurrencies: TCurrencySymbol[] = currencies.map(
				(currency) => currency.currency
			);

			const currencyFiatOptions: ICurrencyOption[] = markets
				.map((market) => market.quoteCurrency)
				.filter(
					(currency, index, array) =>
						array.findIndex((item) => item.currency === currency.currency) ===
							index && availableCurrencies.includes(currency.currency)
				)
				.sort((currency) =>
					currency.currency === TCurrencySymbol.BRL ? -1 : 1
				)
				.map((currency) => ({
					value: currency.currency,
					label: currency.currency,
					subLabel: currency.name,
					icon: currency.currency,
					type: currency.type,
					networks: currency.withdraw.networks.map((network) => network.nameId),
				}));

			const currentCurrencyIn = currencyFiatOptions[0]?.value;

			const availableCurrencyOutCurrencies = markets
				.filter((market) => market.quoteCurrency.currency === currentCurrencyIn)
				.map((market) => market.baseCurrency.currency)
				.filter((currency) => availableCurrencies.includes(currency));

			const currencyCryptoOptions: ICurrencyOption[] = markets
				.map((market) => market.baseCurrency)
				.filter(
					(currency, index, array) =>
						array.findIndex((item) => item.currency === currency.currency) ===
							index &&
						availableCurrencyOutCurrencies.includes(currency.currency)
				)
				.sort((currency) =>
					currency.currency === TCurrencySymbol.USDT ? -1 : 1
				)
				.map((currency) => ({
					value: currency.currency,
					label: currency.currency,
					subLabel: currency.name,
					icon: currency.currency,
					type: currency.type,
					networks: currency.withdraw.networks.map((network) => network.nameId),
				}));

			let networks: INetwork[] = [];

			currencies.forEach((currency) => {
				currency.withdraw.networks.forEach((network) => networks.push(network));
			});

			const networkOptions: INetworkOption[] = networks
				.filter(
					(network, index, array) =>
						array.findIndex((item) => item.nameId === network.nameId) === index
				) //exclude duplicate networks
				.map((network) => ({
					value: network.nameId,
					label:
						network.currencySymbol === TCurrencySymbol.BRL
							? network.currencySymbol
							: network.description,
					icon: network.currencySymbol,
					...(network.currencySymbol === TCurrencySymbol.BRL
						? {
								subLabel:
									network.nameId === "internal-mainnet"
										? "e-wallet"
										: network.nameId,
						  }
						: {}),
					walletConfig: {
						placeholder: network.placeholder,
						validateName: network.nameId.split("-")[0],
						maxLength: 42,
					},
				}));

			const currencyOutIsFiat = currencyFiatOptions.some(
				(currency) => currency.value === currencyOut
			);

			const currencyInIsFiat = currencyFiatOptions.some(
				(currency) => currency.value === currencyIn
			);

			if (currencyOut && currencyOutIsFiat) {
				return {
					...{
						currencyInOptions: currencyCryptoOptions,
						currencyOutOptions: currencyFiatOptions,
					},
					networkOptions,
				};
			}

			if (currencyOut && !currencyOutIsFiat) {
				const parsedCurrencyCryptoOption = currencyCryptoOptions.filter(
					(currency) => currency.value === currencyOut
				);

				return {
					...{
						currencyInOptions: currencyFiatOptions,
						currencyOutOptions: parsedCurrencyCryptoOption,
					},
					networkOptions,
				};
			}

			if (currencyIn && currencyInIsFiat) {
				return {
					...{
						currencyInOptions: currencyFiatOptions,
						currencyOutOptions: currencyCryptoOptions,
					},
					networkOptions,
				};
			}

			if (currencyIn && !currencyInIsFiat) {
				const parsedCurrencyCryptoOption = currencyCryptoOptions.filter(
					(currency) => currency.value === currencyIn
				);

				return {
					...{
						currencyInOptions: parsedCurrencyCryptoOption,
						currencyOutOptions: currencyFiatOptions,
					},
					networkOptions,
				};
			}

			return {
				...(swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
					? {
							currencyInOptions: currencyFiatOptions,
							currencyOutOptions: currencyCryptoOptions,
					  }
					: {
							currencyInOptions: currencyCryptoOptions,
							currencyOutOptions: currencyFiatOptions,
					  }),
				networkOptions,
			};
		}, [currencies, markets, swapDirection]);

	const swapFormValidationSchema = Yup.object({
		user: Yup.object().required(""),
		amountIn: Yup.string().required("").typeError(""),
		addressOut: Yup.string().when(["sendAddressOutLater", "currencyOut"], {
			is: (sendAddressOutLater: boolean, currencyOut: ICurrencyOption) => {
				// console.log({sendAddressOutLater, currencyOut});
				return (
					((sendAddressOutLater ?? false) === false &&
						currencyOut?.value !== TCurrencySymbol.BRL) ||
					currencyOut?.value === TCurrencySymbol.BRL
				);
			},
			then: (schema) =>
				schema.test(
					"valid-wallet-address",
					t(`user-view-newUserModal.invalid-fields.invalid-field`),
					async (value, context) => {
						const _values = context.parent as SwapModalFormData;

						if (isEWalletUserModeEnabled === true) return true;
						if (isReadOnly === true) return true;

						console.log(
							"valid-wallet-address",
							{ isEWalletUserModeEnabled },
							`networkOut: ${_values.networkOut.value}`,
							`addressOut: ${_values.addressOut}`,
							{ isDestinationRecipientCheckbox },
							{ value },
							{ isReadOnly },
							[isDestinationRecipientCheckbox, (value ?? "").length === 0],
							!isDestinationRecipientCheckbox && (value ?? "").length === 0
						);

						if (_values.currencyOut?.value === TCurrencySymbol.BRL) {
							if ([TenantAppDomainEnum.EZZEPAY].includes(tenant.name)) {
								if (isDestinationRecipientCheckbox) return false;
							} else {
								if (
									isDestinationRecipientCheckbox &&
									(value ?? "").length === 0
								)
									return true;
							}

							if (!isDestinationRecipientCheckbox && (value ?? "").length === 0)
								return false;

							if (!Util.isStringJsonValid(value!)) return false;

							const _value = JSON.parse(value!);

							if (_values.networkOut?.value === "p2p-mainnet") {
								if (!_value["p2p"]?.["account"]) return false;
							}

							if (_values.networkOut?.value === "internal-mainnet") {
								if (
									!_value["internal"] ||
									!(_value["internal"]["id"] || _value["internal"]["email"])
								)
									return false;
							}

							if (_values.networkOut?.value === "pix-mainnet") {
								if (!(_value["pix"] || _value["bank"])) return false;
								if (_value["pix"] && !_value["pix"]["key"]) return false;
								if (
									_value["bank"] &&
									(!_value["bank"]["code"] ||
										!_value["bank"]["branch"] ||
										!_value["bank"]["account"])
								)
									return false;
								// if (!_value["name"]) return false;
								// if (!_value["taxId"]) return false;
							}

							// console.log("valid-wallet-address[2]", { isDestinationRecipientCheckbox, value, _value })

							return (
								!isDestinationRecipientCheckbox || (value ?? "").length === 0
							);
						}

						if (!value || value?.length < 20) {
							return false;
						}

						return await handleValidateAddress();
					}
				),
		}),
	});

	const handleSubmit = (values: SwapModalFormData) => {
		if (action === "view" || action === "cancel") return;

		const isEntryNetAmountOut = swapQuote
			? swapQuote.entry === "netAmountOut"
			: calculatedSwapQuote?.entry === "netAmountOut";

		if (action === "edit" && request?.id) {
			swapUpdate({
				id: request?.id,
				type: RequestTypeEnum[
					isEntryNetAmountOut
						? values.currencyIn.value === TCurrencySymbol.BRL
							? RequestTypeEnum.swapFiatForExactCrypto
							: RequestTypeEnum.swapCryptoForExactFiat
						: values.currencyIn.value === TCurrencySymbol.BRL
						? RequestTypeEnum.swapExactFiatForCrypto
						: RequestTypeEnum.swapExactCryptoForFiat
				].toString(),
				...(isEntryNetAmountOut
					? {}
					: { amountIn: CurrencyUtil.getNumberByValue(values.amountIn) }),
				...(isEntryNetAmountOut
					? {
							netAmountOut: CurrencyUtil.getNumberByValue(values.netAmountOut),
					  }
					: {}),
				currencyIn: values.currencyIn.value,
				currencyOut: values.currencyOut.value,
				networkIn: values.networkIn.value,
				networkOut: values.networkOut.value,
				...((values.addressOut ?? "") === ""
					? {}
					: { addressOut: values.addressOut }),
				user: values.user?.value,
			});
		} else {
			swapCreate({
				type: RequestTypeEnum[
					isEntryNetAmountOut
						? values.currencyIn.value === TCurrencySymbol.BRL
							? RequestTypeEnum.swapFiatForExactCrypto
							: RequestTypeEnum.swapCryptoForExactFiat
						: values.currencyIn.value === TCurrencySymbol.BRL
						? RequestTypeEnum.swapExactFiatForCrypto
						: RequestTypeEnum.swapExactCryptoForFiat
				].toString(),
				...(isEntryNetAmountOut
					? {}
					: { amountIn: CurrencyUtil.getNumberByValue(values.amountIn) }),
				...(isEntryNetAmountOut
					? {
							netAmountOut: CurrencyUtil.getNumberByValue(values.netAmountOut),
					  }
					: {}),
				currencyIn: values.currencyIn.value,
				currencyOut: values.currencyOut.value,
				networkIn: !!isEWalletUserModeEnabled
					? "internal-mainnet"
					: values.networkIn.value,
				networkOut: !!isEWalletUserModeEnabled
					? "internal-mainnet"
					: values.networkOut.value,
				...((values.addressOut ?? "") === ""
					? {}
					: { addressOut: values.addressOut }),
				user: values.user?.value!,
			});
		}
	};

	const userSwap = (): IUserOption | null => {
		if (!swap) return null;
		return {
			value: swap.user?.id,
			label: swap.user?.name,
			subLabel: user?.email,
			user: swap.user as UserModel,
		};
	};

	const swapForm = useFormik<SwapModalFormData>({
		initialValues: useMemo(() => {
			return {
				type: "swapExactAmountIn",
				amountIn: "",
				currencyIn: currencyInOptions[0],
				currencyOut: currencyOutOptions[0],
				netAmountOut: "",
				// network: networkOptions.filter((network) =>
				// 	currencyOutOptions[0]?.networks?.includes(network.value)
				// )[0],
				networkIn: networkOptions
					.filter((network) =>
						currencyInOptions[0]?.networks?.includes(network.value)
					)
					.find(
						(network) =>
							network.value ===
							([TenantAppDomainEnum.EZZEPAY].includes(tenant.name)
								? "p2p-mainnet"
								: [TenantAppDomainEnum.BANKEI].includes(tenant.name)
								? "internal-mainnet"
								: "pix-mainnet")
					) as any,
				networkOut: networkOptions.filter((network) =>
					currencyOutOptions[0]?.networks?.includes(network.value)
				)[0],
				sendAddressOutLater: enableInformAddressLater,
				addressOut: "",
				user: userSwap(),
			};
		}, []),
		validationSchema: swapFormValidationSchema,
		validateOnChange: false,
		validateOnMount: true,
		onSubmit: handleSubmit,
	});

	const [swapQuote, setSwapQuote] = useState<SwapModalQuoteAmountIn>();
	const [calculatedSwapQuote, setCalculatedSwapQuote] =
		useState<QuoteSwapDataResponse>();

	const debouncedQuote = useDebouncedValue(swapQuote, 300);

	useEffect(() => {
		if (debouncedQuote) {
			if (!debouncedQuote.networkOut && !debouncedQuote.networkIn) return;

			if (debouncedQuote.amountIn && Number.isNaN(debouncedQuote.amountIn))
				return;

			if (
				debouncedQuote.netAmountOut &&
				Number.isNaN(debouncedQuote.netAmountOut)
			)
				return;

			handleQuoteSwap({
				amountIn: debouncedQuote.amountIn,
				netAmountOut: debouncedQuote.netAmountOut,
				currencyIn: debouncedQuote.currencyIn,
				currencyOut: debouncedQuote.currencyOut,
				networkIn: !!isEWalletUserModeEnabled
					? "internal-mainnet"
					: debouncedQuote.networkIn ?? null,
				networkOut: !!isEWalletUserModeEnabled
					? "internal-mainnet"
					: debouncedQuote.networkOut ?? null,
				addressOut: debouncedQuote.addressOut ?? null,
				user: debouncedQuote.user,
			})
				.then((data) => {
					if (data) {
						if (
							data.amountInMin >
								(data.entry && data.entry === "amountIn"
									? debouncedQuote.amountIn || 0
									: CurrencyUtil.getNumberByValue(data.amountIn)) ||
							data.amountInMax <
								(data.entry && data.entry === "amountIn"
									? debouncedQuote.amountIn || 0
									: CurrencyUtil.getNumberByValue(data.amountIn))
						) {
							setIsAmountInInvalid(true);
						} else {
							setIsAmountInInvalid(false);
						}

						if (
							data.netAmountOutMin >
								(data.entry && data.entry === "netAmountOut"
									? debouncedQuote.netAmountOut || 0
									: CurrencyUtil.getNumberByValue(data.netAmountOut)) ||
							data.netAmountOutMax <
								(data.entry && data.entry === "netAmountOut"
									? debouncedQuote.netAmountOut || 0
									: CurrencyUtil.getNumberByValue(data.netAmountOut))
						) {
							setIsNetAmountOutInvalid(true);
						} else {
							setIsNetAmountOutInvalid(false);
						}
					}

					setCalculatedSwapQuote((prev) => {
						if (!prev?.requestedAt || !debouncedQuote?.requestedAt) {
							return { ...data, requestedAt: debouncedQuote?.requestedAt };
						}

						if (moment(debouncedQuote.requestedAt).isAfter(prev.requestedAt)) {
							return { ...data, requestedAt: debouncedQuote?.requestedAt };
						}

						return prev; //set the previous value if the calculated value is outdated compared to previous value;
					});
				})
				.finally(() => {
					swapForm.setTouched({ amountIn: true, netAmountOut: true });
				});
		}
	}, [debouncedQuote]);

	useEffect(() => {
		if (calculatedSwapQuote) {
			if (calculatedSwapQuote.entry === "amountIn") {
				swapForm.setFieldValue(
					"netAmountOut",
					calculatedSwapQuote.netAmountOut
				);
			} else {
				swapForm.setFieldValue("amountIn", calculatedSwapQuote.amountIn, true);
			}
		}
	}, [calculatedSwapQuote]);

	const handleChangeCurrency = async (
		option: ICurrencyOption,
		field: "currencyIn" | "currencyOut" = "currencyOut"
	) => {
		const _networkField = field === "currencyIn" ? "networkIn" : "networkOut";
		const _networkNameId = (
			field === "currencyIn" ? currencyInOptions : currencyOutOptions
		).filter((currency) => currency.value === option.value)[0]?.networks[0];

		const _networkOptions =
			networkOptions.filter(
				(network: any) => network.value === _networkNameId
			) || [];

		await swapForm.setFieldValue(_networkField, _networkOptions[0]);

		console.log(
			`::handleChangeCurrency::`,
			{
				[field]: option.value,
				[_networkField]: _networkNameId,
			}
			// `${field}`,
			// `${option.value}`,
			// `${_networkField}`,
			// _networkNameId,
			// _networkOptions[0].value,
		);

		calculatePairAmount({
			field: (
				swapQuote
					? swapQuote.entry === "netAmountOut"
					: calculatedSwapQuote?.entry === "netAmountOut"
			)
				? "netAmountOut"
				: "amountIn",
			networkIn:
				_networkField === "networkIn"
					? _networkOptions[0].value
					: swapForm.values.networkIn.value,
			networkOut:
				_networkField === "networkOut"
					? _networkOptions[0].value
					: swapForm.values.networkOut.value,
			[field]: option.value,
		});
	};

	const handleChangeSwapDirection = useCallback(async () => {
		if (isQuotingSwap) return;

		// console.log('::handleChangeSwapDirection::', 'amountIn', `${calculatedSwapQuote?.amountIn}`, `${swapForm.values.amountIn}`, ` = ${CurrencyUtil.getNumberByValue(calculatedSwapQuote?.amountIn ?? swapForm.values.amountIn)}`);
		// console.log('::handleChangeSwapDirection::', 'netAmountOut', `${calculatedSwapQuote?.netAmountOut}`, `${swapForm.values.netAmountOut}`, ` = ${CurrencyUtil.getNumberByValue(calculatedSwapQuote?.netAmountOut ?? swapForm.values.netAmountOut)}`);

		const amountIn = CurrencyUtil.getNumberByValue(
			calculatedSwapQuote?.amountIn ?? swapForm.values.amountIn
		);
		const netAmountOut = CurrencyUtil.getNumberByValue(
			calculatedSwapQuote?.netAmountOut ?? swapForm.values.netAmountOut
		);

		const entry: QuoteSwapDataResponse["entry"] =
			swapQuote?.entry ?? calculatedSwapQuote?.entry ?? "amountIn";

		const networkIn = swapForm.values.networkIn;
		const networkOut = swapForm.values.networkOut;

		const addressOut = !!isEWalletUserModeEnabled
			? swapForm.values.user?.user.id
			: swapForm.values.currencyIn.value === TCurrencySymbol.BRL
			? (
					Util.tryCastObjectToString(
						swapForm.values?.user?.user?.autoFiatExternalWithdrawWalletAddress
					) ??
					([TenantAppDomainEnum.BANKEI].includes(tenant.name)
						? swapForm.values.user?.user.id
						: "") ??
					""
			  ).trim()
			: "";

		await swapForm.setFieldValue("amountIn", netAmountOut);
		await swapForm.setFieldValue("netAmountOut", amountIn);

		await swapForm.setFieldValue("currencyIn", swapForm.values.currencyOut);
		await swapForm.setFieldValue("currencyOut", swapForm.values.currencyIn);

		await swapForm.setFieldValue("networkIn", networkOut);
		await swapForm.setFieldValue("networkOut", networkIn);

		await swapForm.setFieldValue("addressOut", addressOut);

		if (swapForm.values.currencyIn.value === TCurrencySymbol.BRL) {
			// handleDestionationRecipientCheckbox({ target: { checked: (addressOut ?? '').trim() === '' } });
			setIsDestinationRecipientCheckbox((addressOut ?? "").trim() === "");
		}

		// console.log(
		// 	"::swapDirection::",
		// 	SwapModalSwapDirectionEnum[
		// 	swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
		// 		? SwapModalSwapDirectionEnum.fromCryptoToFiat
		// 		: SwapModalSwapDirectionEnum.fromFiatToCrypto
		// 	]
		// );

		setSwapDirection(
			swapDirection === SwapModalSwapDirectionEnum.fromFiatToCrypto
				? SwapModalSwapDirectionEnum.fromCryptoToFiat
				: SwapModalSwapDirectionEnum.fromFiatToCrypto
		);

		toggleExchangeRateMode();

		// console.log(
		// 	"::handleChangeSwapDirection::",
		// 	`${networkOut.value ?? "[null]"}`,
		// 	`${networkIn.value ?? "[null]"}`
		// );

		// console.log(
		// 	"::autoFiatExternalWithdrawWalletAddress::",
		// 	`currencyOut: ${swapForm.values.currencyIn.value},`,
		// 	`networkOut: ${networkIn.value},`,
		// 	`addressOut: ${addressOut ?? ''} (length: ${(addressOut ?? '').length})`,
		// 	`autoFiatExternalWithdrawWalletAddress: ${(swapForm?.values?.user?.user?.autoFiatExternalWithdrawWalletAddress ?? "") ?? ''} (length: ${(swapForm?.values?.user?.user?.autoFiatExternalWithdrawWalletAddress ?? "").length})`,
		// );

		// console.log("::calculatePairAmount::[args][change-direction]", `${entry === "amountIn" ? "netAmountOut" : "amountIn"}`, {
		// 	currencyIn: swapForm.values.currencyOut.value,
		// 	currencyOut: swapForm.values.currencyIn.value,
		// 	networkIn: networkOut.value,
		// 	networkOut: networkIn.value,
		// 	field: entry === "amountIn" ? "netAmountOut" : "amountIn",
		// 	amount: entry === "amountIn" ? Number(amountIn) : Number(netAmountOut),
		// 	addressOut,
		// 	// direction: _newSwapDirection,
		// });

		calculatePairAmount({
			currencyIn: swapForm.values.currencyOut.value,
			currencyOut: swapForm.values.currencyIn.value,
			networkIn: networkOut.value,
			networkOut: networkIn.value,
			field: entry === "amountIn" ? "netAmountOut" : "amountIn",
			amount: entry === "amountIn" ? Number(amountIn) : Number(netAmountOut),
			addressOut,
			// direction: _newSwapDirection,
		});
	}, [
		swapForm.values.currencyIn,
		swapForm.values.currencyOut,
		swapQuote,
		calculatedSwapQuote,
		isQuotingSwap,
	]);

	const handleValidateAddress = async (): Promise<boolean> => {
		try {
			const parsedNetwork =
				swapForm?.values?.networkOut?.walletConfig?.validateName;
			const { isValid, address } = WalletService.addressValidate(
				swapForm?.values?.addressOut,
				parsedNetwork
			);

			if (isValid === true && address !== swapForm.values.addressOut) {
				await swapForm.setFieldValue("addressOut", address);
			}

			return isValid;
		} catch (e) {
			console.log(
				`::handleValidateAddress::`,
				`[ERROR]`,
				swapForm.values.networkOut?.walletConfig?.validateName ?? "not-found",
				swapForm.values.addressOut
			);
			return false;
		}
	};

	const user: UserModel | undefined = useMemo(() => {
		return swapForm.values.user?.user;
	}, [swapForm.values.user]);

	const users: UserModel[] | undefined = getUsersResponse?.data?.rows;
	const [defaultUsers, setDefaultUsers] = useState<IUserOption[] | []>([]);

	const usersAccountOptions: IUserOption[] = useMemo(() => {
		if (defaultUsers.length === 0) {
			setDefaultUsers(users ? users.map(parseUserToOption) : []);
		}

		if (userId) {
			const responseUser = getUsersResponse?.data?.rows[0];

			return responseUser ? [parseUserToOption(responseUser)] : [];
		} else if (action === "edit" && swap) {
			return [parseUserToOption(swap.user as UserModel)];
		} else {
			return users ? users.map(parseUserToOption) : [];
		}
	}, [getUsersResponse, users]);

	useEffect(() => {
		const fetchUserData = async () => {
			if (action !== "new" && swap) {
				const currentUser = parseUserToOption(swap.user as UserModel);
				if (
					!!isEWalletUserModeEnabled ||
					swapForm?.values?.networkOut?.value === "internal-mainnet"
				) {
					await swapForm.setFieldValue("addressOut", currentUser?.user?.id);
					await swapForm.setFieldTouched("addressOut", true);
				}
				return await swapForm.setFieldValue("user", currentUser);
			}

			if (userId && usersAccountOptions[0] && swap) {
				const currentUser = parseUserToOption(swap.user as UserModel);
				if (
					!!isEWalletUserModeEnabled ||
					swapForm?.values?.networkOut?.value === "internal-mainnet"
				) {
					await swapForm.setFieldValue("addressOut", currentUser?.user?.id);
					await swapForm.setFieldTouched("addressOut", true);
				}

				return await swapForm.setFieldValue("user", currentUser);
			}

			if (userId && userData) {
				const currentUser = parseUserToOption(userData);

				return await swapForm.setFieldValue("user", currentUser);
			}

			if (!userId && swapForm.values.user && usersAccountOptions.length > 0) {
				const currentUser = usersAccountOptions.find(
					(option) => option.value === swapForm.values.user?.value
				);
				if (
					!!isEWalletUserModeEnabled ||
					swapForm?.values?.networkOut?.value === "internal-mainnet"
				) {
					await swapForm.setFieldValue("addressOut", currentUser?.user?.id);
					await swapForm.setFieldTouched("addressOut", true);
				}

				return await swapForm.setFieldValue("user", currentUser);
			}
		};

		fetchUserData();
	}, [usersAccountOptions, swap]);

	useEffect(() => {
		if (action != "new") {
			return;
		}

		const fetchUserData = async () => {
			if (
				_cryptohubInfoBalancesData?.data?.userId &&
				usersAccountOptions?.length
			) {
				const userOptionFilter = {
					page: 0,
					limit: 1,
					total: 0,
					search: {
						id: _cryptohubInfoBalancesData?.data?.userId,
					},
				};

				const userData = await userService.getUsers(userOptionFilter);
				const userOption = parseUserToOption(userData.data.rows[0]);

				if (userData) {
					if (!swapForm.values.user) {

						await swapForm.setFieldValue("user", userOption);
						setTimeout(() => swapForm.setFieldTouched("user", true));
					}

					if (
						!!isEWalletUserModeEnabled ||
						swapForm?.values?.networkOut?.value === "internal-mainnet"
					) {
						await swapForm.setFieldValue("addressOut", userOption?.user?.id);
						await swapForm.setFieldTouched("addressOut", true);
					}

					if (swapForm.values.currencyOut.value === TCurrencySymbol.BRL) {
						const addressOut = (
							Util.tryCastObjectToString(
								userOption?.user?.autoFiatExternalWithdrawWalletAddress
							) ?? ""
						).trim();
						await swapForm.setFieldValue("addressOut", addressOut);
						setTimeout(() => swapForm.setFieldTouched("addressOut", true));
						setIsDestinationRecipientCheckbox((addressOut ?? "").trim() === "");
					}
				}
			}
		};

		fetchUserData();
	}, [_cryptohubInfoBalancesData, usersAccountOptions]);

	useEffect(() => {
		if (swap) {
			let currencyIn;
			let currencyOut;
			let networkIn;
			let networkOut;
			let addressOut;

			if (
				[
					RequestTypeEnum.swapExactCryptoForFiat,
					RequestTypeEnum.swapCryptoForExactFiat,
				].includes(swap.type.id)
			) {
				setSwapDirection(SwapModalSwapDirectionEnum.fromCryptoToFiat);
				setExchangeRateMode("InOut");

				currencyIn = currencyOutOptions.find(
					(option) =>
						option.value === swap.currencyIn.currency || currencyOutOptions[0]
				);

				currencyOut = currencyInOptions.find(
					(option) =>
						option.value === swap.currencyOut.currency || currencyInOptions[0]
				);

				networkIn =
					networkOptions.find(
						(option) => option.value === swap.networkIn.nameId
					) ??
					networkOptions.filter((option) =>
						currencyOut.networks?.includes(option.value)
					)[1];

				networkOut =
					networkOptions.find(
						(option) => option.value === swap.networkOut.nameId
					) ??
					networkOptions.filter((option) =>
						currencyOut.networks?.includes(option.value)
					)[1];

				if (swapForm.values.currencyOut.value === TCurrencySymbol.BRL) {
					setIsDestinationRecipientCheckbox((addressOut ?? "").trim() === "");
				}
			} else {
				setSwapDirection(SwapModalSwapDirectionEnum.fromFiatToCrypto);
				setExchangeRateMode("OutIn");

				currencyIn =
					currencyInOptions.find(
						(option) => option.value === swap.currencyIn.currency
					) || currencyInOptions[0];

				currencyOut =
					currencyOutOptions.find(
						(option) => option.value === swap.currencyOut.currency
					) || currencyOutOptions[0];

				// network = networkOptions.find(
				// 	(option) => option.value === swap.networkOut.nameId
				// ) ??
				// 	networkOptions.filter((option) =>
				// 		currencyOut.networks?.includes(option.value)
				// 	)[1];
			}

			swapForm.setFieldValue("amountIn", swap.amountIn);
			swapForm.setFieldValue("currencyIn", currencyIn);

			swapForm.setFieldValue("netAmountOut", swap.netAmountOut);
			swapForm.setFieldValue("currencyOut", currencyOut);

			// swapForm.setFieldValue("network", network);
			swapForm.setFieldValue("networkIn", networkIn ?? swap.networkOut);
			swapForm.setFieldValue("networkOut", networkOut ?? swap.networkOut);

			swapForm.setFieldValue("addressOut", swap.addressOut).then(() => {
				swapForm.setFieldTouched("addressOut").then(() => {
					swapForm.setFieldError("addressOut", undefined);
				});
			});

			if (swap.currencyOut.currency === TCurrencySymbol.BRL) {
				setIsDestinationRecipientCheckbox((addressOut ?? "").trim() === "");
			}

			if (enableInformAddressLater) {
				swapForm.setFieldValue("sendAddressOutLater", !swap.addressOut);
			} else {
				swapForm.setFieldValue(
					"sendAddressOutLater",
					!isEWalletUserModeEnabled
				);
			}

			const type =
				swap.type.id === RequestTypeEnum.swapExactFiatForCrypto
					? "amountIn"
					: "netAmountOut";

			let ticker: { [currency in TCurrencySymbol]: ICurrencyTicker } = {} as {
				[currency in TCurrencySymbol]: ICurrencyTicker;
			};

			currencies.forEach((currency) => {
				ticker[currency.currency] = currency.ticker || {
					BRL: 0,
					USD: 0,
					EUR: 0,
					name: "",
					provider: "",
					updatedAt: 0,
				};
			});

			setCalculatedSwapQuote({
				amountIn: swap.amountIn,
				currencyIn: swap.currencyIn.currency,
				netAmountOut: swap.netAmountOut,
				currencyOut: swap.currencyOut.currency,
				entry: type,
				networkAmountFee: swap.fees.network.amount,
				networkCurrencyFee: swap.fees.network.currency,
				serviceAmountFee: swap.fees.service.amount,
				serviceCurrencyFee: swap.fees.service.currency,
				servicePercentageFee: swap.fees.service.percentage,
				ticker: ticker,
				quotes: {
					[swap.currencyOut.currency + swap.currencyIn.currency]:
						swap.rate.amount,
					[swap.currencyIn.currency + swap.currencyOut.currency]: (
						1 / (CurrencyUtil.getNumberByValue(swap.rate.amount) || 1)
					).toFixed(6),
				},
				netAmountOutMax: 0,
				amountInMax: 0,
				amountInMin: 0,
				netAmountOutMin: 0,
			});
		}
	}, [swap]);

	const getWalletValidationIconProps = (): {
		name: AvailableIconsType;
		variant: IconVariantsType;
	} => {
		const isWalletValid =
			swapForm.errors.addressOut === undefined &&
			swapForm.values.addressOut !== "";

		return {
			name: isWalletValid ? "CheckCircleFill" : "DashCircleFill",
			variant: isWalletValid ? "success" : "danger",
		};
	};

	const calculatePairAmount = ({
		field,
		amount,
		currencyIn,
		currencyOut,
		network,
		networkIn,
		networkOut,
		addressOut,
		userId,
		direction,
	}: SwapModalCalculatePairAmountParams) => {
		// console.log("::calculatePairAmount::[args]", `${field}`, {
		// 	...(field ? { field } : {}),
		// 	...(amount ? { amount } : {}),
		// 	...(currencyIn ? { currencyIn } : {}),
		// 	...(currencyOut ? { currencyOut } : {}),
		// 	...(network ? { network } : {}),
		// 	...(networkIn ? { networkIn } : {}),
		// 	...(networkOut ? { networkOut } : {}),
		// 	...(addressOut ? { addressOut } : {}),
		// 	...(userId ? { userId } : {}),
		// 	...(direction ? { direction } : {}),
		// });

		const entry =
			(field === "network"
				? calculatedSwapQuote?.entry ?? "amountIn"
				: field) === "netAmountOut"
				? "netAmountOut"
				: "amountIn";

		// console.log(`::::::`, entry, calculatedSwapQuote?.entry, CurrencyUtil.getNumberByValue(
		// 	entry === "netAmountOut"
		// 		? swapForm.values.netAmountOut
		// 		: swapForm.values.amountIn
		// ));

		// console.log("::calculatePairAmount::[params]", {
		// 	type: (
		// 		(field === "netAmountOut")
		// 			? "swapExactNetAmountOut"
		// 			: "swapExactAmountIn"
		// 	),
		// 	entry: entry,
		// 	[entry]:
		// 		amount ??
		// 		CurrencyUtil.getNumberByValue(
		// 			entry === "netAmountOut"
		// 				? (swapForm.values.netAmountOut ?? '0.0')
		// 				: (swapForm.values.amountIn ?? '0.0')
		// 		),
		// 	currencyIn: currencyIn || swapForm.values.currencyIn.value,
		// 	currencyOut: currencyOut || swapForm.values.currencyOut.value,
		// 	networkIn: networkIn || swapForm.values.networkIn.value,
		// 	networkOut: networkOut || swapForm.values.networkOut.value,
		// 	// [_swapType === SwapModalSwapDirectionEnum.fromFiatToCrypto ? "networkOut" : "networkIn"]: parsedNetwork,
		// 	addressOut: addressOut ?? swapForm.values.addressOut,
		// 	user: userId || swapForm.values.user?.value || "",
		// 	requestedAt: new Date(),
		// });

		// const _swapType = type || swapDirection;

		// if (network) {
		// 	field = calculatedSwapQuote?.entry || "amountIn";
		// }

		// let parsedAddressOut = (
		// 	addressOut === undefined
		// 		?
		// 		swapForm.values.addressOut
		// 		:
		// 		addressOut
		// );

		// const parsedNetwork = network || swapForm.values.network.walletConfig.validateName;

		// if (
		// 	parsedAddressOut
		// 	&& WalletService.addressValidate(
		// 		parsedAddressOut,
		// 		parsedNetwork.walletConfig.validateName
		// 	).isValid === false
		// ) {
		// 	parsedAddressOut = "";
		// }

		setSwapQuote({
			type:
				field === "netAmountOut"
					? "swapExactNetAmountOut"
					: "swapExactAmountIn",
			entry: entry,
			[entry]:
				amount ??
				CurrencyUtil.getNumberByValue(
					entry === "netAmountOut"
						? swapForm.values.netAmountOut ?? "0.0"
						: swapForm.values.amountIn ?? "0.0"
				),
			currencyIn: currencyIn || swapForm.values.currencyIn.value,
			currencyOut: currencyOut || swapForm.values.currencyOut.value,
			networkIn: networkIn || swapForm.values.networkIn.value,
			networkOut: networkOut ?? swapForm.values.networkOut.value,
			// [_swapType === SwapModalSwapDirectionEnum.fromFiatToCrypto ? "networkOut" : "networkIn"]: parsedNetwork,
			addressOut:
				(networkOut ?? swapForm.values.networkOut.value) === "internal-mainnet"
					? userId ?? swapForm.values.user?.value!
					: addressOut ?? swapForm.values.addressOut,
			user: userId || swapForm.values.user?.value || "",
			requestedAt: new Date(),
		});
	};

	const handleRefreshAvailable = async () => {
		if (isLoadingUserData || isSubmitting || isFetchingCryptoHubInfoBalances) {
			return;
		}
		const currentUser = parseUserToOption(
			(await refetchUser()).data?.data.rows[0] as UserModel
		);

		if (
			!!isEWalletUserModeEnabled ||
			swapForm?.values?.networkOut?.value === "internal-mainnet"
		) {
			await swapForm.setFieldValue("addressOut", currentUser?.user?.id);
			await swapForm.setFieldTouched("addressOut", true);
		}

		return await swapForm.setFieldValue("user", currentUser);
	};

	const handleChangeSendAddressOutLater = () => {
		if (!swapForm.values.sendAddressOutLater) {
			swapForm.setFieldValue("addressOut", "");
			swapForm.setFieldError("addressOut", undefined);
		}

		swapForm.setFieldValue(
			"sendAddressOutLater",
			!swapForm.values.sendAddressOutLater
		);

		swapForm.validateForm({
			...swapForm.values,
			sendAddressOutLater: !swapForm.values.sendAddressOutLater,
			addressOut: "",
		});
	};

	const handleCancelSwap = () => {
		swapCancel(request?.id!);
	};

	const [isAmountInInvalid, setIsAmountInInvalid] = useState(false);
	const [isNetAmountOutInvalid, setIsNetAmountOutInvalid] = useState(false);

	const [isDestinationRecipientCheckbox, setIsDestinationRecipientCheckbox] =
		useState(true);

	const [isEWalletUserModeEnabled, setIsEWalletUserModeEnabled] = useState(
		![TenantAppDomainEnum.EZZEPAY].includes(tenant.name)
	);

	const enableInformAddressLater = false || !isEWalletUserModeEnabled;

	const [isEWalletUserDepositModeEnabled, setIsEWalletUserDepositModeEnabled] =
		useState(![TenantAppDomainEnum.EZZEPAY].includes(tenant.name));

	const [
		isEWalletUserWithdrawModeEnabled,
		setIsEWalletUserWithdrawModeEnabled,
	] = useState(![TenantAppDomainEnum.EZZEPAY].includes(tenant.name));

	const handleDestionationRecipientCheckbox = async ({
		target,
	}: {
		target: { checked: boolean };
	}) => {
		if (!!isEWalletUserModeEnabled || isReadOnly) return;

		const _isDestinationRecipientCheckbox =
			target?.checked ?? !isDestinationRecipientCheckbox;

		const networkOut = networkOptions
			.filter((network) =>
				currencyOutOptions[0]?.networks?.includes(network.value)
			)
			.find(
				(network) =>
					network.value ===
					([TenantAppDomainEnum.EZZEPAY].includes(tenant.name)
						? "p2p-mainnet"
						: [TenantAppDomainEnum.BANKEI].includes(tenant.name)
						? "internal-mainnet"
						: "pix-mainnet")
			);

		const addressOut =
			_isDestinationRecipientCheckbox === true
				? ""
				: Util.tryCastObjectToString(
						swapForm.values.user?.user?.autoFiatExternalWithdrawWalletAddress
				  ) ??
				  ([TenantAppDomainEnum.BANKEI].includes(tenant.name)
						? swapForm.values.user?.user.id
						: "");

		setIsDestinationRecipientCheckbox(_isDestinationRecipientCheckbox);
		await swapForm.setFieldValue("addressOut", addressOut);
		await swapForm.setFieldTouched("addressOut");

		await swapForm.setFieldValue("networkOut", networkOut);

		calculatePairAmount({
			field: "network",
			networkOut: networkOut?.value,
			addressOut: addressOut,
		});
	};

	const handleEWalletBalanceMode = async () => {
		if (isReadOnly) return;

		const addressOut = !!isEWalletUserModeEnabled
			? null
			: swapForm.values.user?.user.id;
		await swapForm.setFieldValue("addressOut", addressOut);
		await swapForm.setFieldTouched("addressOut");

		setIsEWalletUserModeEnabled(!isEWalletUserModeEnabled);

		calculatePairAmount({
			field: calculatedSwapQuote?.entry ?? "amountIn",
			addressOut: addressOut ?? "",
		});
	};

	const handleEWalletUserDepositModeEnabled = () => {
		setIsEWalletUserDepositModeEnabled(!isEWalletUserDepositModeEnabled);
	};

	const handleEWalletUserWithdrawModeEnabled = () => {
		setIsEWalletUserWithdrawModeEnabled(!isEWalletUserWithdrawModeEnabled);
	};

	const getBalanceTotalByCurrency = (currency: TCurrencySymbol) => {
		let _balance = 0;

		if (isEWalletUserModeEnabled === true) {
			const _balances = user?.userBalances?.find(
				(result) => result.currency.currency === currency
			);

			if (_balances) {
				_balance = Number(_balances.total!);
			}
		} else {
			const _balances = _cryptohubInfoBalancesData?.data?.rows?.find(
				(result: any) => result.currency === currency
			);

			if (_balances) {
				// available = _balances.baas.available + _balances.exchange.available;
				_balance = _balances.exchange.total!;
			}
		}

		return _balance;
	};

	const getBalanceAvailableByCurrency = (currency: TCurrencySymbol) => {
		let _balance = 0;
		if (isEWalletUserModeEnabled === true) {
			const _balances = user?.userBalances?.find(
				(result) => result.currency.currency === currency
			);

			if (_balances) {
				_balance = Number(Util.truncate(_balances.available, 6));
			}
		} else {
			const _balances = _cryptohubInfoBalancesData?.data?.rows?.find(
				(result: any) => result.currency === currency
			);

			if (_balances) {
				// available = _balances.baas.available + _balances.exchange.available;
				_balance = Number(Util.truncate(_balances.exchange.available, 6));
			}
		}
		return Number(_balance).toFixed(currency === TCurrencySymbol.BRL ? 2 : 6);
	};

	const availableCurrencyIn = useMemo(() => {
		return getBalanceAvailableByCurrency(swapForm?.values?.currencyIn.value);
	}, [
		user,
		_cryptohubInfoBalancesData,
		swapForm?.values?.currencyIn.value,
		isEWalletUserModeEnabled,
	]);

	const setMaxAvailableAmountIn = async () => {
		if (isSubmitting) return;

		await swapForm.setFieldValue("amountIn", availableCurrencyIn);

		calculatePairAmount({
			field: "amountIn",
			amount: Number(availableCurrencyIn ?? "0"),
		});
	};

	const disableSubmitButton =
		isQuotingSwap ||
		!swapForm.isValid ||
		!swapForm.values.amountIn ||
		!swapForm.values.netAmountOut ||
		isSubmitting ||
		isAmountInInvalid ||
		isNetAmountOutInvalid ||
		isQuotingSwap ||
		(!!isEWalletUserModeEnabled &&
			(Number(availableCurrencyIn) <= 0 ||
				Number(availableCurrencyIn) <
					CurrencyUtil.getNumberByValue(swapForm.values.amountIn)));

	const onChangeSearch = async (text: string) => {
		if (text) {
			filter.search = Util.mountDefaultUserSearch(text, filter.search);

			if (text?.length >= 3 || !text?.length) {
				const response = await refetchUsers();

				const users = response?.data?.data.rows;

				return users ? users.map(parseUserToOption) : [];
			} else {
				return defaultUsers;
			}
		}
	};

	return (
		<>
			<Modal
				ref={modalRef}
				title={t(`swap-modal.title.${action}`)}
				titleIcon={"SwapHorizontal"}
				visible={true}
				onClose={onClose}
				contentOverflowY={"auto"}
				fullHeightModal={true}
				onClickOutside={() => {}}
				backgroundColor={"new"}
				customWidth={{
					width: "100%",
					maxWidth: "768px",
					minWidth: "180px",
				}}
				isLoading={isSubmitting}
				footer={
					<View style={[styles.footer]}>
						<View
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-start",
							}}
						>
							{action !== "new" && (
								<>
									<View
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "flex-start",
										}}
									>
										<Text size={"12px"}>{request?.id}</Text>
										<Text variant="gray" size={"11px"}>
											{Util.formatDate(
												request?.swap.createdAt,
												undefined,
												"L HH[h]mm"
											)}
										</Text>
									</View>
								</>
							)}
						</View>

						<View style={[styles.footerButtons]}>
							<Button
								variant={"secondary"}
								size={"small"}
								fillVariant={"ghost"}
								label={t("swap-modal.buttons.cancel")}
								onClick={() => onClose()}
								disabled={isSubmitting}
							/>

							{action !== "view" && (
								<Button
									icon={
										<Icon
											name={
												isEdit
													? isCancel
														? "CloseCircleIcon"
														: "SaveDisk"
													: "Send2"
											}
											fontSize={"20px"}
										/>
									}
									label={t(`swap-modal.buttons.submit.${action}`)}
									size={"small"}
									variant={isCancel ? "danger" : undefined}
									onClick={() =>
										isCancel ? handleCancelSwap() : swapForm.handleSubmit()
									}
									disabled={disableSubmitButton && !isCancel}
									disableHover={disableSubmitButton && !isCancel}
								/>
							)}
						</View>
					</View>
				}
				footerDivider={true}
			>
				{currencyInOptions.length > 0 && currencyOutOptions.length > 0 ? (
					<View style={[styles.container]}>
						<View
							style={[styles.card, { minWidth: 360, height: "fit-content" }]}
						>
							<SwapModalTitle
								title={t(
									`${
										swapForm.values?.currencyOut.value === TCurrencySymbol.BRL
											? "swap-modal.destination.account"
											: "swap-modal.origin.account"
									}`
								)}
								icon={"PersonFill"}
								rightAddon={
									!isMobile ? (
										<View
											style={{
												justifyContent: "space-between",
												flexDirection: "row",
												display: "flex",
											}}
										>
											{![TenantAppDomainEnum.EZZEPAY].includes(tenant.name) ? (
												<Checkbox
													size={"small"}
													checked={isEWalletUserModeEnabled}
													disabled={isReadOnly}
													readonly={isReadOnly}
													onChange={handleEWalletBalanceMode}
													label={t(
														`swap-modal.e-wallet.user-mode-${
															isEWalletUserModeEnabled ? "enabled" : "disabled"
														}`
													)}
												/>
											) : (
												<View>
													<Text
														style={{
															fontSize: 12,
															color: isEWalletUserModeEnabled ? "green" : "red",
														}}
													>
														{t(
															`swap-modal.e-wallet.user-mode-${
																isEWalletUserModeEnabled
																	? "enabled"
																	: "disabled"
															}`
														)}
													</Text>
												</View>
											)}
										</View>
									) : undefined
								}
							/>

							<SelectSearch
								options={usersAccountOptions}
								selectedOption={swapForm.values.user}
								icons={{
									label: "PersonFill",
									subLabel: "EnvelopeFill",
								}}
								readonly={!!userId || isEdit || isReadOnly}
								disabled={isSubmitting || isQuotingSwap}
								width={"100%"}
								noOptionsMessage={
									isLoadingUsers && usersAccountOptions?.length === 0
										? t("swap-modal.origin.loading-accounts")
										: undefined
								}
								onChange={async (option: any) => {
									let addressOut: any;
									let networkOut: any;

									if (!userId && !isEdit) {
										if (
											swapForm.values.currencyOut.value === TCurrencySymbol.BRL
										) {
											addressOut = (
												Util.tryCastObjectToString(
													option?.user?.autoFiatExternalWithdrawWalletAddress
												) ?? ""
											).trim();

											await swapForm.setFieldValue("addressOut", addressOut);
											await swapForm.setFieldTouched("addressOut");

											if (
												swapForm.values.currencyOut.value ===
												TCurrencySymbol.BRL
											) {
												setIsDestinationRecipientCheckbox(
													(addressOut ?? "").trim() === ""
												);
											}

											// console.log(option.user.isAutoFiatExternalWithdrawWalletAddressEnabled);
											// console.log(option.user.autoFiatExternalWithdrawWalletAddress);
											// console.log(option.user.autoFiatExternalWithdrawWalletNetwork);
											// console.log(option.user.autoFiatExternalWithdrawWalletProvider);
										}

										if (
											!!isEWalletUserModeEnabled ||
											swapForm?.values?.networkOut?.value === "internal-mainnet"
										) {
											await swapForm.setFieldValue(
												"addressOut",
												option?.user?.id
											);
											await swapForm.setFieldTouched("addressOut", true);
										}

										await swapForm.setFieldValue("user", option);
										await swapForm.setFieldValue(
											"user",
											parseUserToOption(
												(
													await refetchUser()
												).data?.data.rows[0] as UserModel
											)
										);
										setCalculatedSwapQuote(undefined);

										if (calculatedSwapQuote) {
											calculatePairAmount({
												field: calculatedSwapQuote.entry,
												userId: option?.value,
												addressOut,
												networkOut,
											});
										}
									}
								}}
								loadOptions={onChangeSearch}
								defaultOptions={defaultUsers}
							/>

							<View>
								<SwapModalTitle
									title={t("swap-modal.origin.title")}
									icon={"BoxArrowInDown"}
									badgeText={t("swap.table.rows.exactly")}
									showBadge={
										swapQuote
											? swapQuote.entry === "amountIn"
											: calculatedSwapQuote?.entry === "amountIn"
									}
									rightAddon={
										!isMobile ? (
											<SwapAvailableBalanceView
												value={availableCurrencyIn}
												currency={
													swapForm.values?.currencyIn?.value ??
													TCurrencySymbol.BRL
												}
												isReadOnly={isReadOnly}
												balanceVisible={balanceVisible}
												setBalanceVisible={setBalanceVisible}
												handleRefreshAvailable={handleRefreshAvailable}
												isFetchingCryptoHubInfoBalances={
													isFetchingCryptoHubInfoBalances
												}
											/>
										) : undefined
									}
								/>
							</View>

							<View>
								<Input
									autoComplete={"off" /* "new-amountIn" */}
									paddingRight={"176px"}
									size={"large"}
									coin={
										swapForm.values.currencyIn.value === TCurrencySymbol.BRL
											? "BRL"
											: "BTC"
									}
									disabled={
										isSubmitting ||
										(isQuotingSwap && !!quotingSwapData?.netAmountOut)
									}
									readOnly={isReadOnly}
									value={swapForm.values.amountIn}
									onChange={async (value: any) => {
										if (isReadOnly) return;
										await swapForm.setFieldValue("amountIn", value);
									}}
									onKeyUp={async () => {
										if (isReadOnly) return;

										setIsAmountInInvalid(false);
										await swapForm.setFieldTouched("amountIn");

										calculatePairAmount({ field: "amountIn" });
									}}
									decimalConfig={{
										decimalScale:
											swapForm.values.currencyIn?.value === TCurrencySymbol.BRL
												? 2
												: 6,
										decimalsLimit:
											swapForm.values.currencyIn?.value === TCurrencySymbol.BRL
												? 2
												: 6,
									}}
									variant={
										swapForm.touched.amountIn &&
										swapForm.errors.amountIn !== undefined
											? "danger"
											: undefined
									}
									errorMessage={
										calculatedSwapQuote
											? swapForm.touched.amountIn &&
											  (swapForm.errors.amountIn || isAmountInInvalid)
												? t(`swap-modal.fields-errors.amount-min-max`, {
														min: `${CurrencyUtil.formatCurrency(
															calculatedSwapQuote?.amountInMin,
															calculatedSwapQuote?.currencyIn ||
																swapForm.values.currencyIn?.value
														)}`,
														max: `${CurrencyUtil.formatCurrency(
															calculatedSwapQuote?.amountInMax,
															calculatedSwapQuote?.currencyIn ||
																swapForm.values.currencyIn?.value
														)}`,
												  })
												: ""
											: ""
									}
									rightAddon={{
										buttons: [
											...(isReadOnly ||
											swapDirection ===
												SwapModalSwapDirectionEnum.fromFiatToCrypto ||
											!!isEWalletUserModeEnabled
												? [
														<MaxValueButton
															onPress={setMaxAvailableAmountIn}
															disabled={
																isSubmitting ||
																(isQuotingSwap &&
																	!!quotingSwapData?.netAmountOut)
															}
														/>,
												  ]
												: []),
											<Select
												size={"medium"}
												inputMinWidth={"112px"}
												selectPadding={"0px"}
												placeholder={""}
												isSearchable={false}
												paddingRight={"0px"}
												iconSize={24}
												iconSet={"currency"}
												disabled={false}
												readonly={isReadOnly || currencyInOptions.length === 1}
												options={currencyInOptions}
												value={swapForm.values.currencyIn}
												onChange={async (option) => {
													await swapForm.setFieldValue("currencyIn", option);
													await handleChangeCurrency(option, "currencyIn");
												}}
											/>,
										],
									}}
								/>
							</View>

							<SwapModalTitle
								title={t("swap-modal.destination.title")}
								icon={"BoxArrowUp"}
								badgeText={t("swap.table.rows.exactly")}
								showBadge={
									swapQuote
										? swapQuote.entry === "netAmountOut"
										: calculatedSwapQuote?.entry === "netAmountOut"
								}
								rightAddon={
									<>
										{action === "new" &&
										!currencyIn &&
										!currencyOut &&
										!(
											calculatedSwapQuote &&
											swapForm.touched.amountIn &&
											(swapForm.errors.amountIn || isAmountInInvalid)
										) ? (
											<SwapChangeTypeButton
												onPress={handleChangeSwapDirection}
												disabled={isQuotingSwap}
											/>
										) : undefined}
										{swapDirection ===
											SwapModalSwapDirectionEnum.fromCryptoToFiat &&
										!isMobile &&
										swapForm.values?.currencyOut?.value !==
											TCurrencySymbol.BRL ? (
											<SwapAvailableBalanceView
												value={availableCurrencyIn}
												currency={
													swapForm.values?.currencyOut?.value ||
													TCurrencySymbol.BRL
												}
												isReadOnly={isReadOnly}
												balanceVisible={balanceVisible}
												setBalanceVisible={setBalanceVisible}
												handleRefreshAvailable={handleRefreshAvailable}
												isFetchingCryptoHubInfoBalances={
													isFetchingCryptoHubInfoBalances
												}
											/>
										) : undefined}
									</>
								}
							/>
							<Input
								autoComplete={"off" /* "new-netAmountOut" */}
								paddingRight={"176px"}
								size={"large"}
								coin={
									swapForm.values.currencyIn.value !== TCurrencySymbol.BRL
										? "BRL"
										: "BTC"
								}
								disabled={
									isSubmitting || (isQuotingSwap && !!quotingSwapData?.amountIn)
								}
								readOnly={isReadOnly}
								onChange={async (value) => {
									await swapForm.setFieldValue("netAmountOut", value);
								}}
								value={swapForm.values.netAmountOut}
								onKeyUp={async () => {
									if (isReadOnly) return;
									setIsNetAmountOutInvalid(false);
									await swapForm.setFieldTouched("netAmountOut");
									calculatePairAmount({ field: "netAmountOut" });
								}}
								decimalConfig={{
									decimalScale:
										swapForm.values.currencyOut.value === TCurrencySymbol.BRL
											? 2
											: 6,
									decimalsLimit:
										swapForm.values.currencyOut.value === TCurrencySymbol.BRL
											? 2
											: 6,
								}}
								errorMessage={
									calculatedSwapQuote
										? swapForm.touched.netAmountOut &&
										  (swapForm.errors.netAmountOut || isNetAmountOutInvalid)
											? t(`swap-modal.fields-errors.amount-min-max`, {
													min: `${CurrencyUtil.formatCurrency(
														calculatedSwapQuote?.netAmountOutMin,
														calculatedSwapQuote?.currencyOut ||
															swapForm.values.currencyOut?.value
													)}`,
													max: `${CurrencyUtil.formatCurrency(
														calculatedSwapQuote?.netAmountOutMax,
														calculatedSwapQuote?.currencyOut ||
															swapForm.values.currencyOut?.value
													)}`,
											  })
											: ""
										: ""
								}
								rightAddon={{
									buttons: [
										<Select
											size={"medium"}
											inputMinWidth={"112px"}
											selectPadding={"0px"}
											placeholder={""}
											iconSize={24}
											iconSet={"currency"}
											disabled={
												isSubmitting ||
												(isQuotingSwap && !!quotingSwapData?.amountIn)
											}
											readonly={isReadOnly || currencyOutOptions?.length === 1}
											options={
												swapDirection ===
												SwapModalSwapDirectionEnum.fromFiatToCrypto
													? currencyOutOptions
													: currencyInOptions
											}
											value={swapForm.values.currencyOut}
											onChange={async (option) => {
												if (isReadOnly) return;
												await swapForm.setFieldValue("currencyOut", option);
												await handleChangeCurrency(option, "currencyOut");
											}}
											menuPortalTarget={modalRef?.current}
										/>,
									],
								}}
							/>

							{isEWalletUserModeEnabled === false && (
								<>
									<SwapModalInputNetwork
										networkField={
											swapDirection ===
											SwapModalSwapDirectionEnum.fromFiatToCrypto
												? "networkOut"
												: "networkIn"
										}
										// swapType={swapDirection}
										disabled={isSubmitting || isQuotingSwap}
										swapForm={swapForm}
										readonly={currencyOut ? true : isReadOnly}
										networkOptions={networkOptions}
										portal={modalRef?.current!}
										calculatePairAmount={calculatePairAmount}
									/>
								</>
							)}

							{isEWalletUserModeEnabled === false &&
								!currencyIn &&
								!currencyOut &&
								swapForm.values.currencyOut?.type ===
									CurrencyTypeEnum.CRYPTO && (
									<View>
										<Input
											autoComplete={"off" /* "new-addressOut" */}
											size={"large"}
											label={t("swap-modal.destination.wallet")}
											placeholder={
												swapForm.values.networkOut?.walletConfig?.placeholder ||
												""
											}
											value={swapForm.values.addressOut}
											disabled={
												isSubmitting || swapForm.values.sendAddressOutLater
											}
											readOnly={
												swapForm.values.sendAddressOutLater || isReadOnly
											}
											onChange={(value) =>
												swapForm.setFieldValue("addressOut", value)
											}
											onKeyUp={(event: any) => {
												if (isSubmitting || isReadOnly) return;
												if (event.code === "ControlLeft") {
													return;
												}

												swapForm.setFieldTouched("addressOut");
											}}
											variant={
												swapForm.touched.addressOut &&
												swapForm.errors.addressOut !== undefined
													? "danger"
													: undefined
											}
											maxLength={
												swapForm.values.networkOut?.walletConfig?.maxLength ||
												42
											}
											rightAddon={
												!swapForm.values.sendAddressOutLater
													? {
															buttons: [
																swapForm.values.sendAddressOutLater ||
																isReadOnly ? null : (
																	<Icon
																		{...getWalletValidationIconProps()}
																		fontSize={"16px"}
																	/>
																),
															],
													  }
													: undefined
											}
										/>

										{enableInformAddressLater && (
											<View
												style={{
													display: "flex",
													position: "absolute",
													right: 0,
													height: 16,
													flexDirection: "row",
													alignItems: "center",
												}}
											>
												<Checkbox
													label={
														<TouchableWithoutFeedback
															disabled={isSubmitting}
															onPress={() => handleChangeSendAddressOutLater()}
														>
															<Text size={"12px"}>
																{t("swap-modal.destination.inform-later")}
															</Text>
														</TouchableWithoutFeedback>
													}
													size={"small"}
													checked={swapForm.values.sendAddressOutLater}
													onChange={() => {
														if (isSubmitting || isReadOnly) return;
														handleChangeSendAddressOutLater();
													}}
													disabled={isSubmitting}
												/>
											</View>
										)}
									</View>
								)}

							{swapForm.values?.currencyOut.value === TCurrencySymbol.BRL ? (
								<>
									<View
										style={{
											justifyContent: "space-between",
											flexDirection: "row",
											display: "flex",
										}}
									>
										<View>
											<Text
												variant={"primary"}
												size={"13px"}
												fontStyle={"bold"}
											>
												{t("swap-modal.destination.recipient-details")}
											</Text>
										</View>
										{!(!!isEWalletUserModeEnabled || isReadOnly) && (
											<Checkbox
												size={"small"}
												checked={isDestinationRecipientCheckbox}
												disabled={!!isEWalletUserModeEnabled || isReadOnly}
												readonly={!!isEWalletUserModeEnabled || isReadOnly}
												onChange={handleDestionationRecipientCheckbox}
												label={t(
													"swap-modal.destination.recipient-not-informed"
												)}
											/>
										)}
									</View>
									<Input
										autoComplete={"off" /* "new-addressOut" */}
										size={"large"}
										paddingRight={"176px"}
										/* label={t("swap-modal.destination.recipient-details")} */
										placeholder={
											swapForm.values.networkOut?.walletConfig?.placeholder ??
											""
										}
										value={
											swapForm.values.addressOut
											// ?? (swapForm.values.user
											// 	? maskCpfOrCnpj(swapForm.values.user.user.personId)
											// 	: "")
										}
										disabled={
											!!isEWalletUserModeEnabled ||
											isSubmitting ||
											swapForm.values.sendAddressOutLater
										}
										readOnly={
											!!isEWalletUserModeEnabled ||
											isDestinationRecipientCheckbox ||
											swapForm.values.sendAddressOutLater ||
											isReadOnly
										}
										onChange={async (value: any) => {
											await swapForm.setFieldValue("addressOut", value);
											await swapForm.setFieldTouched("addressOut");
										}}
										onKeyUp={(event: any) => {
											if (isSubmitting || isReadOnly) return;
											if (event.code === "ControlLeft") {
												return;
											}
											swapForm.setFieldTouched("addressOut");
										}}
										variant={
											swapForm.touched.addressOut &&
											swapForm.errors.addressOut !== undefined
												? "danger"
												: undefined
										}
										// maxLength={
										// 	swapForm.values.networkOut?.walletConfig?.maxLength ?? 255
										// }
										rightAddon={{
											buttons: [
												<Select
													size={"medium"}
													inputMinWidth={"112px"}
													selectPadding={"0px"}
													placeholder={""}
													iconSize={24}
													iconSet={"currency"}
													disabled={
														!!isEWalletUserModeEnabled ||
														isReadOnly ||
														isDestinationRecipientCheckbox
													}
													readonly={
														!!isEWalletUserModeEnabled ||
														isReadOnly ||
														isDestinationRecipientCheckbox
													}
													options={networkOptions
														.map((x) => ({
															...x,
															subLabel:
																x.value === "internal-mainnet"
																	? "e-wallet"
																	: x.value,
														}))
														.filter((network) => {
															return swapForm.values.currencyOut.networks?.includes(
																network.value
															);
														})}
													onChange={async (network) => {
														await swapForm.setFieldValue("networkOut", network);
														await swapForm.setFieldValue("addressOut", "");
														calculatePairAmount({
															field: "network",
															networkOut: network.value,
															addressOut: "",
														});
													}}
													value={swapForm.values.networkOut}
													menuPortalTarget={modalRef?.current}
												/>,
											],
										}}
										errorMessage={
											swapForm.touched.addressOut && swapForm.errors.addressOut
										}
									/>
								</>
							) : undefined}
						</View>

						<View style={{ display: "flex", flexDirection: "column", gap: 12 }}>
							<SwapModalDetailsCard
								calculatedSwapQuote={calculatedSwapQuote!}
								swapForm={swapForm}
								action={action}
								swapQuote={swapQuote!}
								request={request}
								isQuotingSwap={isQuotingSwap}
								toggleExchangeRateMode={toggleExchangeRateMode}
								exchangeRateMode={exchangeRateMode}
							/>
							<SwapModalFundsCard swapForm={swapForm} request={request} />
						</View>
					</View>
				) : (
					<NotAvailableView />
				)}
			</Modal>
		</>
	);
};

export { UserSwapModal };
